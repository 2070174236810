<script lang="ts" setup>
import type { GamesMenu } from "@/types";

const props = withDefaults(
	defineProps<{
		type?: "link" | "button";
		menu: GamesMenu;
		showLobby?: boolean;
	}>(),
	{ type: "link" }
);

const { t } = useT();
const isGuest = useIsGuest();
const { isDesktop } = useDevice();
const { isTabActive, getTitle, navigationContainer, scrollToActiveTab, lobbyActive, activeUrl } = useNavigationGame();

const showCatalogGames = useState<boolean>("show-catalog-games");

const emit = defineEmits<{ (event: "clickTab", link: string): void }>();

const handleClick = (url: string) => {
	const isRootUrl = url === "/";
	showCatalogGames.value = !isRootUrl;
	emit("clickTab", !isRootUrl ? url : "/home/lobby/");

	if (props.type === "button") {
		activeUrl.value = url;
	}

	if (isDesktop) {
		scrollToActiveTab();
	}
};

const handleGuestChange = (value: boolean) => {
	if (!value && props.showLobby) {
		lobbyActive();
	}
};

watch(isGuest, handleGuestChange);
</script>

<template>
	<div :class="['row-bar', { 'is-guest': isGuest }]">
		<div ref="navigationContainer" class="nav">
			<MNavigationGame
				v-if="showLobby"
				link="/"
				:active="isTabActive('/')"
				icon-link="48/lobby"
				:title="t('Lobby')"
				data-tid="nav-lobby"
				:type="type"
				@click-tab="handleClick"
			/>
			<template v-for="item in menu" :key="item.url">
				<MNavigationGame
					:link="item.url || ''"
					:active="isTabActive(`${item.url}`)"
					:icon-html="item.img || ''"
					:title="item.title || ''"
					:data-tid="`nav-${getTitle(item?.title)}`"
					:type="type"
					@click-tab="handleClick"
				/>
			</template>
		</div>
	</div>
</template>

<style lang="scss">
.is-guest > [data-tid*="favorites"] {
	display: none;
}
</style>

<style scoped lang="scss">
.row-bar {
	display: flex;
	width: calc(100% + 32px);
	transform: translateX(-16px);
	overflow: hidden;
	background: var(--neutral-5);
	box-shadow: 0 0 15px 15px var(--neutral-5);
	position: sticky;
	z-index: 12;
	top: var(--top-height);

	@include media-breakpoint-down(md) {
		margin-bottom: 12px;
	}

	.nav {
		display: flex;
		width: 100%;
		padding: 0 16px 20px;
		margin-bottom: -20px;
		overflow: auto;
		gap: 8px;
	}
}
</style>
